export default function Resume() {
  return (
    <>
      <h1>Resume</h1>
      <iframe
        src="/resume.pdf#navpanes=0"
        type="application/pdf"
        width="725"
        height="1000"
        title="Resume"
      />
    </>
  );
}
