import React from "react";
import BlogPosts from "../components/BlogPosts"; // Ensure the correct path to BlogPosts

export default function Blog() {
  return (
    <div>
      <h1>Blog</h1>
      <BlogPosts />
    </div>
  );
}
