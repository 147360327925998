import Entries from "../Entries";

export default function Projects() {
  return (
    <>
      <h1>Projects</h1>
      <Entries />
    </>
  );
}
